import request from '@/utils/request'


// 查询模板报警信息列表
export function listSampleAlarmAttr(query) {
  return request({
    url: '/gather/sample/alarm/attr/list',
    method: 'get',
    params: query
  })
}

// 查询模板报警信息分页
export function pageSampleAlarmAttr(query) {
  return request({
    url: '/gather/sample/alarm/attr/page',
    method: 'get',
    params: query
  })
}

// 查询模板报警信息详细
export function getSampleAlarmAttr(data) {
  return request({
    url: '/gather/sample/alarm/attr/detail',
    method: 'get',
    params: data
  })
}

// 新增模板报警信息
export function addSampleAlarmAttr(data) {
  return request({
    url: '/gather/sample/alarm/attr/add',
    method: 'post',
    data: data
  })
}

// 修改模板报警信息
export function updateSampleAlarmAttr(data) {
  return request({
    url: '/gather/sample/alarm/attr/edit',
    method: 'post',
    data: data
  })
}

// 删除模板报警信息
export function delSampleAlarmAttr(data) {
  return request({
    url: '/gather/sample/alarm/attr/delete',
    method: 'post',
    data: data
  })
}
